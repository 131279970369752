export interface SignalResponse {
    status: boolean
    data: Signals[]
    total_records: number
    total_pages: number
    current_page: number
    page_size: number
}

export interface Signals {
    id: string
    tradesignal_numeric_id: string
    instrument_token: string
    square_off_processing: boolean
    square_off: any
    signal_squarred_off: boolean
    transaction_type: string
    order_type: string
    is_amo: boolean
    tag: string
    disclosed_quantity: number
    trigger_price: number
    price: number
    admin_id: string
    product: string
    quantity: number
    validity: string
    start_action: string
    start_action_datetime: string
    start_action_price: number
    end_action: string
    sl_order_type: string
    sl_trigger_price: number
    sl_price: number
    target_order_type: string
    target_trigger_price: number
    target_price: number
    no_of_users: number
    trade_name: string
    bot_name: any
    status: boolean
    trade_status: string
    square_off_type: any
    symbol_token: string
    is_processing: any
    order_placed_price: number
    created_at: string
    updated_at: string
}

export interface SignalFilter {
    from_date:string | undefined;
    to_date:string | undefined;
    param:string | undefined;
    page:number | undefined
}

export const initial_signal_state:SignalFilter={
    from_date:undefined,
    to_date:undefined,
    page:1,
    param:undefined
}

export interface Edit {
    signal_numeric_id: string | undefined
    updated_target_value: string | undefined
    updated_sl_value: string | undefined
}

export interface BotDetails{
    bot_name:string,
    instrument:string
}

export interface AddTrade{
    trading_symbol:string,
    bot_name:string,
    product: string,
    price: number ,
    instrument_token: string,
    order_type: string,
    transaction_type: string,
    is_amo: boolean,
    sl_value: number ,
    target_value: number,
    symbol_token:string,
    price_type:string
}

export interface TradeData{
    weekly: number
    segment: string
    name: string
    exchange: string
    expiry: number
    instrument_type: string
    asset_symbol: string
    underlying_symbol: string
    instrument_key: string
    lot_size: number
    freeze_quantity: number
    exchange_token: number
    minimum_lot: number
    tick_size: number
    asset_type: string
    underlying_type: string
    trading_symbol: string
    strike_price: number
    qty_multiplier: number
    isin: any
    short_name: any
    security_type: any
    asset_key: string
    underlying_key: string
    last_trading_date: any
    price_quote_unit: any
    timestamp: string
    ts: string
}