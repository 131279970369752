import { AreaChart, Card } from '@tremor/react';
import styles from './home.module.scss';

const chartdata = [
    {
      date: 'Jan 23',
      'Distance Running': 167,
      'Road Cycling': 145,
      'Open Water Swimming': 135,
      'Hatha Yoga': 115,
      'Street Basketball': 150,
    },
    {
      date: 'Feb 23',
      'Distance Running': 125,
      'Road Cycling': 110,
      'Open Water Swimming': 155,
      'Hatha Yoga': 85,
      'Street Basketball': 180,
    },
    {
      date: 'Mar 23',
      'Distance Running': 156,
      'Road Cycling': 149,
      'Open Water Swimming': 145,
      'Hatha Yoga': 90,
      'Street Basketball': 130,
    },
    {
      date: 'Apr 23',
      'Distance Running': 165,
      'Road Cycling': 112,
      'Open Water Swimming': 125,
      'Hatha Yoga': 105,
      'Street Basketball': 170,
    },
    {
      date: 'May 23',
      'Distance Running': 153,
      'Road Cycling': 138,
      'Open Water Swimming': 165,
      'Hatha Yoga': 100,
      'Street Basketball': 110,
    },
    {
      date: 'Jun 23',
      'Distance Running': 124,
      'Road Cycling': 145,
      'Open Water Swimming': 175,
      'Hatha Yoga': 75,
      'Street Basketball': 140,
    },
  ];
  

function Home() {
    return (
        <div className={styles.home}>
            <div className={styles.homeTop}>
                <Card
                    className="mx-auto max-w-xs"
                    >
                    <p className="text-tremor-default text-tremor-content-strong dark:text-dark-tremor-content">Amount Traded</p>
                    <p className="text-3xl text-center text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">150,736 ₹</p>
                </Card>
                <Card
                    className="mx-auto max-w-xs"
                    >
                    <p className="text-tremor-default text-tremor-content-strong dark:text-dark-tremor-content">Profit Ratio</p>
                    <p className="text-3xl text-center text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">9,436 ₹</p>
                </Card>
                <Card
                    className="mx-auto max-w-xs"
                    >
                    <p className="text-tremor-default text-tremor-content-strong dark:text-dark-tremor-content">Trades Placed</p>
                    <p className="text-3xl text-center text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">20</p>
                </Card>
            </div>
            <div className={styles.chart}>
                <Card>
                    <AreaChart
                        className="h-72"
                        data={chartdata}
                        index="date"
                        categories={[
                            'Distance Running',
                            'Road Cycling',
                            'Open Water Swimming',
                        ]}
                        colors={['blue-700', 'red', 'green']}
                        yAxisWidth={30}
                    />
                </Card>
                

            </div>
        </div>
    )
}

export default Home