import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './redux_slices/auth_slice';
import { routeSlice } from './redux_slices/route_slice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

const persistConfig = {
    key: 'root',
    storage,
    /* blacklist: ['chatRoomSlice'], */
};

const rootReducer = combineReducers({
    authSlice: authSlice.reducer,
    routeSlice: routeSlice.reducer,
});


const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
