import { commonError, noDataFound, sessionExpired, unprocessableEntity } from "./errors";

export function handleError(error:any, type?:string){
    if(error.response.status===401){
        sessionExpired();
    }
    else if(error.response.status===422){
        unprocessableEntity()
    }
/*     else if(error.response.status === 404){
        noDataFound()
    } */
    else{
        commonError(error.response.data.message );
    }
}