// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lora:ital,wght@0,400..700;1,400..700&family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_home__qA4gR {
  width: 100%;
  padding-top: 4rem;
}
.home_home__qA4gR .home_homeTop__WZhYc {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
}
.home_home__qA4gR .home_chart__sn2Ea {
  margin-top: 5rem;
  height: 40vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.module.scss","webpack://./src/global/styles/layout.scss"],"names":[],"mappings":"AAKA;EACI,WAAA;EACA,iBAAA;AAAJ;AACI;ECPA,aAAA;EACA,mBDO8B;ECN9B,SDMmC;ECLnC,8BDKyC;AAI7C;AAFI;EACI,gBAAA;EACA,YAAA;AAIR","sourcesContent":["@import '../../global/styles/colour.scss';\n@import '../../global/styles/font.scss';\n@import '../../global/styles/layout.scss';\n@import '../../global/styles/media.scss';\n\n.home{\n    width: 100%;\n    padding-top: 4rem;\n    .homeTop{\n        @include main-flex-layout(row, 2rem, space-between);\n    }\n    .chart{\n        margin-top: 5rem;\n        height: 40vh;\n    }\n}","@mixin main-flex-layout( $side, $gap, $justify){\n    display: flex;\n    flex-direction: $side;\n    gap:$gap;\n    justify-content: $justify;\n}\n\n@mixin mobile-view{\n        @media (max-width: 600px) { @content; }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `home_home__qA4gR`,
	"homeTop": `home_homeTop__WZhYc`,
	"chart": `home_chart__sn2Ea`
};
export default ___CSS_LOADER_EXPORT___;
