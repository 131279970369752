import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../store';
import RouteState from "../../interface/RouteState";

const initialState: RouteState = {
    home:{
        names:"Home",
        status:true
    },
    bots: {
        names:"Bots",
        status:false
    },
    transactions: {
        names:"Transactions",
        status:false
    },
    users: {
        names:"Users",
        status:false
    },
    signal: {
        names:"Signal",
        status:false
    },
    settings: {
        names:"Settings",
        status:false
    },
    health: {
        names:"Health",
        status:false
    },
};

export const routeSlice = createSlice({
    name: 'routeSlice',
    initialState,
    reducers: {
        setHome: (state) => {
            state.home.status = true;
            state.bots.status = false;
            state.transactions.status = false;
            state.users.status = false;
            state.signal.status = false;
            state.settings.status = false;
            state.health.status = false;
        },
        setBots: (state) => {
            state.home.status = false;
            state.bots.status = true;
            state.transactions.status = false;
            state.users.status = false;
            state.signal.status = false;
            state.settings.status = false;
            state.health.status = false;
        },
        setTransactions: (state) => {
            state.home.status = false;
            state.bots.status = false;
            state.transactions.status = true;
            state.users.status = false;
            state.signal.status = false;
            state.settings.status = false;
            state.health.status = false;
        },
        setUsers: (state) => {
            state.home.status = false;
            state.bots.status = false;
            state.transactions.status = false;
            state.users.status = true;
            state.signal.status = false;
            state.settings.status = false;
            state.health.status = false;
        },
        setSignal: (state) => {
            state.home.status = false;
            state.bots.status = false;
            state.transactions.status = false;
            state.users.status = false;
            state.signal.status = true;
            state.settings.status = false;
            state.health.status = false;
        },
        setSettings: (state) => {
            state.home.status = false;
            state.bots.status = false;
            state.transactions.status = false;
            state.users.status = false;
            state.signal.status = false;
            state.settings.status = true;
            state.health.status = false;
        },
        setHealth: (state) => {
            state.home.status = false;
            state.bots.status = false;
            state.transactions.status = false;
            state.users.status = false;
            state.signal.status = false;
            state.settings.status = false;
            state.health.status = true;
        },
        resetRoute: (state) => {
            state.home.status = false;
            state.bots.status = false;
            state.transactions.status = false;
            state.users.status = false;
            state.signal.status = false;
            state.settings.status = false;
            state.health.status = false;
        },
    },
});

export const  {
    setHome,
    setBots,
    setTransactions,
    setUsers,
    setSignal,
    setSettings,
    setHealth,
    resetRoute,
} = routeSlice.actions;

export const selectRoute = (state: RootState) => state.routeSlice;

export default routeSlice.reducer;
