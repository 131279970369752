import React from 'react';

type CurrencyProps = {
    amount: number;
};

function formatCurrency(amount: number): string {
    const formattedAmount = Math.abs(amount).toFixed(2);
    return amount < 0 ? `- ₹${formattedAmount}` : `₹${formattedAmount}`;
}

export const Currency: React.FC<CurrencyProps> = ({ amount }) => {
    return <span>{formatCurrency(amount)}</span>;
};