import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import RouteState from '../../../interface/RouteState';
import styles from './sidebar.module.scss';
import { TbLockFilled } from "react-icons/tb";
import { AiOutlineHome } from "react-icons/ai";
import { RiRobot3Line } from "react-icons/ri";
import { HiOutlineDocumentText } from "react-icons/hi";
import { FaRegUser } from "react-icons/fa6";
import { IoFlashOutline } from "react-icons/io5";
import { LuSettings } from "react-icons/lu";
import { BsClipboardCheck } from "react-icons/bs";
import { setBots, setHealth, setHome, setSettings, setSignal, setTransactions, setUsers } from '../../../app/redux_slices/route_slice';
import { Button } from '@tremor/react';
import { setLogout } from '../../../app/redux_slices/auth_slice';

export default function Sidebar() {
    const navItems: RouteState = useAppSelector((state) => state.routeSlice);
    const dispatch = useAppDispatch();
    return (
        <div className={styles.sidebar}>
            <Link onClick={()=>dispatch(setHome())} to={'/home'}><div style={navItems.home.status ? {color:"#D67253"}:{color:"#101010"}} className={styles.navItem}><AiOutlineHome /> Home</div></Link>
            <Link onClick={()=>dispatch(setBots())} to={'/bots'}><div style={navItems.bots.status ? {color:"#D67253"}:{color:"#101010"}} className={styles.navItem}><RiRobot3Line /> Bots</div></Link>
            <Link onClick={()=>dispatch(setTransactions())} to={'/transactions'}><div style={navItems.transactions.status ? {color:"#D67253"}:{color:"#101010"}} className={styles.navItem}><HiOutlineDocumentText /> Transactions</div></Link>
            <Link onClick={()=>dispatch(setUsers())} to={'/users'}><div style={navItems.users.status ? {color:"#D67253"}:{color:"#101010"}} className={styles.navItem}><FaRegUser /> Users</div></Link>
            <Link onClick={()=>dispatch(setSignal())} to={'/signal'}><div style={navItems.signal.status ? {color:"#D67253"}:{color:"#101010"}} className={styles.navItem}><IoFlashOutline /> Signal</div></Link>
            <Link onClick={()=>dispatch(setSettings())} to={'/settings'}><div style={navItems.settings.status ? {color:"#D67253"}:{color:"#101010"}} className={styles.navItem}><LuSettings /> Settings</div></Link>
            <Link onClick={()=>dispatch(setHealth())} to={'/health/check'}><div style={navItems.health.status ? {color:"#D67253"}:{color:"#101010"}} className={styles.navItem}><BsClipboardCheck /> Health Check</div></Link>
            <div className={styles.logout}>
                <Button onClick={()=>dispatch(setLogout())} variant='secondary' color='red'>Logout</Button>
            </div>
        </div>
    );
}
