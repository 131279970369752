// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lora:ital,wght@0,400..700;1,400..700&family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar_nav__cDrSP {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 0;
  justify-content: space-between;
  padding: 1.4rem 3rem;
  background-color: #32354C;
}
.navbar_nav__cDrSP .navbar_title__O0BYJ {
  color: #D67253;
  font-size: 2rem;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/global/Componnets/Navbar/navbar.module.scss","webpack://./src/global/styles/layout.scss","webpack://./src/global/styles/colour.scss"],"names":[],"mappings":"AAKA;EACI,aAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;ECTA,aAAA;EACA,mBDS0B;ECR1B,MDQ+B;ECP/B,8BDOkC;EAElC,oBAAA;EACA,yBEde;AFgBnB;AADI;EACI,cEfK;EFgBL,eAAA;EACA,gBAAA;AAGR","sourcesContent":["@import '../../styles/font.scss';\n@import '../../styles/colour.scss';\n@import '../../styles/layout.scss';\n@import '../../styles/media.scss';\n\n.nav{\n    z-index: 1000;\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    @include main-flex-layout(row, 0, space-between);\n\n    padding: 1.4rem 3rem;\n    background-color: $navbar-background;\n    .title{\n        color:$navbar-text;\n        font-size: 2rem;\n        font-weight: 500;\n    }\n}","@mixin main-flex-layout( $side, $gap, $justify){\n    display: flex;\n    flex-direction: $side;\n    gap:$gap;\n    justify-content: $justify;\n}\n\n@mixin mobile-view{\n        @media (max-width: 600px) { @content; }\n}","$navbar-background:#32354C;\n$navbar-text:#D67253;\n$navbar-plan-text:#D67253;\n$table-head-background:#B1D5FD;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `navbar_nav__cDrSP`,
	"title": `navbar_title__O0BYJ`
};
export default ___CSS_LOADER_EXPORT___;
