import { Button, Title } from "@tremor/react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import styles from './signal.module.scss';

interface SignalDetails{
    hide : ()=>void
    signalDetails:any
}

function SignalDetails({hide, signalDetails}:SignalDetails) {
    return (
        <div className={styles.signalDetails}>
            <div><Link to={'/signal'}><Button onClick={()=>{if(hide) hide();}} color='orange' variant='light' icon={FaArrowLeft}>Back</Button></Link></div>
            <Title className={styles.title}>Signal Details</Title>
            <div className={styles.userData}>
                <Title className={styles.data}><div className={styles.userDetailsName}>Signal ID</div> : <div className={styles.userDetailsName}>{signalDetails?.tradesignal_numeric_id}</div></Title>
                <Title className={styles.data}><div className={styles.userDetailsName}>Bot</div> : <div className={styles.userDetailsName}>{signalDetails?.bot_name}</div></Title>
                <Title className={styles.data}><div className={styles.userDetailsName}>Instrument</div> : <div className={styles.userDetailsName}>{signalDetails?.instrument_token}</div></Title>
                <Title className={styles.data}><div className={styles.userDetailsName}>Buy/Sell/Stop Loss Limit</div> : <div className={styles.userDetailsName}>₹{signalDetails?.price} | ₹{signalDetails?.start_action_price} | ₹{signalDetails?.sl_price}</div></Title>
                <Title className={styles.data}><div className={styles.userDetailsName}>Buy/Sell Price</div> : <div className={styles.userDetailsName}>₹{signalDetails?.price}</div></Title>
                <Title className={styles.data}><div className={styles.userDetailsName}>Total Subscribed Users </div> : <div className={styles.userDetailsName}>{signalDetails?.no_of_users}</div></Title>
                <Title className={styles.data}><div className={styles.userDetailsName}>Users with trade</div> : <div className={styles.userDetailsName}>{signalDetails?.no_of_users}</div></Title>
                <Title className={styles.data}><div className={styles.userDetailsName}>Buy Signal Time</div> : <div className={styles.userDetailsName}>{signalDetails?.start_action_datetime}</div></Title>
                <Title className={styles.data}><div className={styles.userDetailsName}>Sell Signal time</div> : <div className={styles.userDetailsName}>{signalDetails?.start_action_datetime}</div></Title>
                <Title className={styles.data}><div className={styles.userDetailsName}>Profit/Loss</div> : <div className={styles.userDetailsName}>{signalDetails?.tradesignal_numeric_id}</div></Title>
            </div>
        </div>
    )
}

export default SignalDetails