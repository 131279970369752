export interface View{
    bg:boolean,
    popUps:PopUps
}

export interface PopUps{
    addSignal:boolean
    editSignal:boolean
}

export const editSignal: View={
    bg:true,
    popUps:{
        addSignal:false,
        editSignal:true
    }
}

export const addSignal : View = {
    bg:true,
    popUps:{
        addSignal:true,
        editSignal:false
    }
}

export const initial_state : View = {
    bg:false,
        popUps:{
            addSignal:false,
            editSignal:false
        }
}