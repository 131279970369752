import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import styles from './navbar.module.scss';
import { Title } from '@tremor/react';
import { setLogout, setTenant } from '../../../app/redux_slices/auth_slice';
import { baseUrl } from '../../../app/shared';
import axios from 'axios';
import { handleError } from '../../ErrorHandling/errohandler';

export default function Navbar(){
    const dispatch= useAppDispatch();
    const auth = useAppSelector((state) => state.authSlice);
    const route = useAppSelector((state)=> state.routeSlice)

    async function getDetails(){
        try {
            const response = await axios.get(`${baseUrl}/api/tenant/get-comany-info`);
            console.log("the response is "+response);
            if (response.status === 200) {
                console.log(response.data.company_name);
                dispatch(setTenant(response.data.company_name))
            }
        } catch (error: any) {
            if(error.response.status===401){
                dispatch(setLogout());
            }
            else{
                handleError(error);
            }
        }
    }

    useEffect(()=>{
        getDetails();
    },[route]);

    return(
        <nav className={styles.nav}>
            <Title className={styles.title}>{auth.tenant}</Title>
        </nav>
    )
}