import { Button, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, TextInput } from '@tremor/react';
import styles from './transaction.module.scss';
import { IoSearchSharp } from 'react-icons/io5';
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import tableData from './transaction.json';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../app/shared';
import { useAuthHeaders } from '../../app/header';
import { initial_transaction_filter, TradeDataResponse, TransactionFilter } from '../../interface/transaction';
import { handleError } from '../../global/ErrorHandling/errohandler';
import { Currency } from '../../global/Componnets/utils/Currency';
import { useEnterKey } from '../../global/Componnets/utils/enterAction';

function Transaction() {
    const [transactionData, setTransactionData]=useState<TradeDataResponse>();
    const {data_header}=useAuthHeaders();
    const [filter, setFilter]=useState<TransactionFilter>(initial_transaction_filter);

    async function getTransactionData({param, signal_id, page}:TransactionFilter) {
        console.log(param , signal_id);
        var url = `${baseUrl}/api/admin/get-customer-transactions?page=${page}&size=10`;
        if(param) url += `&param=${encodeURIComponent(param)}`
        if(signal_id) url += `&signal_id=${encodeURIComponent(signal_id)}`
        try {
            const response = await axios.get(url,data_header);
            if(response.status===201 || response.status===200){
                setTransactionData(response.data);
            }
        } catch (error) {
            handleError(error, `For filter ${filter.signal_id && `${filter.signal_id}`} ${filter.signal_id && `,${filter.signal_id}`} `);
        }
    }

    useEnterKey(() => getTransactionData(filter));

    useEffect(()=>{
        getTransactionData({param:undefined, signal_id:undefined,page:1});
    },[])

    useEffect(()=>{
        filter.param ==="" && getTransactionData({param:undefined, signal_id:undefined,page:1});
    },[filter.param])
    useEffect(()=>{
        filter.signal_id ==="" && getTransactionData({param:undefined, signal_id:undefined,page:1});
    },[filter.signal_id])
    return (
    <div className={styles.transaction}>
        <div className={styles.filters}>
            <div className={styles.search}>
                <TextInput onValueChange={(value)=>setFilter({...filter, param:value})} placeholder='Search By Name, Mobile Number, Email'/>
                <TextInput onValueChange={(value)=>setFilter({...filter, signal_id:value})} className={styles.id} placeholder='Signal ID'/>
            </div>
            <Button onClick={()=>getTransactionData({param:filter.param, signal_id:filter.signal_id, page:1})} variant='secondary' color={'orange'} icon={IoSearchSharp}>Search</Button>
        </div>
        <div className={styles.tableContainer}>
            <div className={styles.pagination}>
                <div className={styles.pageLeft}>
                    <p>In View <span style={{color: transactionData?.in_view_profit ? (transactionData.in_view_profit < 0 ? "red" : "green") : "black"}}><Currency amount={transactionData?.in_view_profit || 0} /></span></p>
                    <p>Last week <span style={{color: transactionData?.last_7_days_profit ? (transactionData.last_7_days_profit < 0 ? "red" : "green") : "black"}}><Currency amount={transactionData?.last_7_days_profit || 0} /></span></p>
                    <p>Overall <span style={{color: transactionData?.overall_profit ? (transactionData.overall_profit < 0 ? "red" : "green") : "black"}}><Currency amount={transactionData?.overall_profit || 0} /></span></p>
                </div>
                <div className={styles.pageRight}>
                    <p>Showing page {transactionData?.current_page} of {transactionData?.total_pages}</p> 
                        <FaAngleLeft onClick={transactionData?.current_page !== 1 ? ()=>getTransactionData({page:(transactionData?.current_page || 1)-1,param:filter.param,signal_id:filter.signal_id}) : undefined} />
                        <FaAngleRight onClick={transactionData?.current_page !== transactionData?.total_pages ? ()=>getTransactionData({page:(transactionData?.current_page || 1)+1,param:filter.param,signal_id:filter.signal_id}) : undefined} />
                </div>
            </div>
            <div className={styles.table}>
                <Table className={styles.mainTable}>
                    <TableHead className={styles.tableHead}>
                        <TableRow className={styles.tableRow}>
                            <TableHeaderCell style={{color:"#D87E60"}} className={styles.headItems}>Signal ID</TableHeaderCell>
                            <TableHeaderCell style={{color:"#D87E60"}}>Client Name</TableHeaderCell>
                            <TableHeaderCell style={{color:"#D87E60"}}>Date</TableHeaderCell>
                            <TableHeaderCell style={{color:"#D87E60"}}>Buy/Sell Time</TableHeaderCell>
                            <TableHeaderCell style={{color:"#D87E60"}}>Buy/Sell</TableHeaderCell>
                            <TableHeaderCell style={{color:"#D87E60", textAlign:"center"}}>Quantity</TableHeaderCell>
                            <TableHeaderCell style={{color:"#D87E60", textAlign:"center"}}>Profit/Loss</TableHeaderCell>
                            <TableHeaderCell style={{color:"#D87E60" , textAlign:"center"}}>Status</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            transactionData?.data.map((value, index)=>(
                                <TableRow key={index}>
                                    <TableCell>{value.tradesignal_numeric_id}</TableCell>
                                    <TableCell>{value.name}</TableCell>
                                    <TableCell>{value.start_action_datetime}</TableCell>
                                    <TableCell>{value.start_action_datetime}</TableCell>
                                    <TableCell>₹{value.start_action_price}/ ₹{value.square_off_price}</TableCell>
                                    <TableCell style={{ textAlign:"center"}}>{value.quantity}</TableCell>
                                    <TableCell style={{ textAlign:"center"}}>{value.profit}</TableCell>
                                    <TableCell style={{ textAlign:"center"}}>{value.transaction_status}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </div>
        </div>
    </div>
    )
}

export default Transaction