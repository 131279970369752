import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

interface AuthState {
    auth: string | undefined
    login_status:boolean
    tenant: string | undefined
}


const initialState: AuthState = {
    auth: undefined,
    login_status:false,
    tenant: undefined
}

export const authSlice = createSlice({
    name: 'authSlice',

    initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<string>) => {
        state.auth = action.payload
        },
        setLogin:(state)=>{
            state.login_status=true;
        },
        setLogout:(state)=>{
            state.login_status=false;
            state.auth=undefined;
            state.tenant=undefined;
        },
        setTenant:(state, action: PayloadAction<string>)=>{
            state.tenant = action.payload;
        }
    },
})

export const { setAuth, setLogin, setLogout , setTenant} = authSlice.actions

export const selectAuth = (state: RootState) => state.authSlice

export default authSlice.reducer