export interface AllBotData {
    status: boolean
    data: Data[]
    total_records: number
    total_pages: number
    current_page: number
    page_size: number
}

export interface Data {
    bot_name: string
    instrument: string
    type: string
    users_subscribed: any
    status: string
}

export interface Filter {
    bot_name?:string | undefined
    page:number,
    limit:number
}

export interface NewBot{
    bot_name:string,
    instrument:string,
    type: /* "standard" | "premium", */ string
}

export const initial_state_newBot: NewBot ={
    bot_name:"",
    instrument:"",
    type:"Standard"
}

export const initial_state:Filter= {
    page:1,
    limit:10
}