export interface TradeDataResponse {
    status: boolean
    data: TradeData[]
    total_records: number
    total_pages: number
    current_page: number
    page_size: number
    last_7_days_profit: number
    overall_profit: number
    in_view_profit: number
}

export interface TradeData {
    id: string
    transaction_numeric_id: string
    tradesignal_numeric_id: string
    signal_id: string
    customer_id: string
    admin_id: string
    start_action: string
    start_action_price: any
    start_action_datetime: string
    end_action: string
    profit: any
    capital_used: any
    trade_name: string
    signal_name: any
    bot_name: string
    quantity: number
    product: string
    validity: string
    price: number
    tag: string
    instrument_token: string
    order_type: string
    transaction_type: string
    disclosed_quantity: number
    trigger_price: number
    is_amo: boolean
    is_successful: boolean
    status: boolean
    transaction_status: any
    order_placed_price: any
    trade_status: any
    user_broker: any
    square_off_type: any
    initial_order_trade: any
    stop_loss_order_trade: any
    profit_booking_order_trade: any
    initial_order_failure_reason: string
    square_off_time: any
    square_off_price: any
    created_at: string
    updated_at: string
    name: string
    email: string
    phone: string
}

export interface TransactionFilter{
    param:string | undefined;
    signal_id:string | undefined;
    page: number | undefined;
}

export const initial_transaction_filter: TransactionFilter = {
    param: undefined,
    signal_id: undefined,
    page:1
};