import styles from './bots.module.scss';
import { Button, TextInput, Title } from '@tremor/react';
import { useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import { initial_state_newBot, NewBot } from '../../interface/Bot';
import axios from 'axios';
import { baseUrl } from '../../app/shared';
import { useAuthHeaders } from '../../app/header';
import { handleError } from '../../global/ErrorHandling/errohandler';

interface UserDetailsProps {
    hide: () => void;
}

function AddBot({ hide }: UserDetailsProps ) {
    const [newBot, setNewBot]=useState<NewBot>(initial_state_newBot);
    const {data_header}=useAuthHeaders();
    
    async function createBot(new_bot_state:NewBot){
        try {
            const response = await axios.post(`${baseUrl}/api/admin/add-bot`,[new_bot_state], data_header);
            if(response.status===200 || response.status===201){
                hide();
            }
        } catch (error) {
            handleError(error);
        }
    }
    return (
        <div className={styles.addBot}>
            <div><Button onClick={()=>{hide()}} color='orange' variant='light' icon={FaArrowLeft}>Back</Button></div>
            <form className={styles.details}>
                <Title className={styles.head}>Bots &gt; Add New</Title>
                <div className={styles.inputs}>
                    <div className={styles.inputSet}>
                        <div style={{width:"30%"}} className={styles.field}>
                            <Title className={styles.fieldName}>Name</Title>
                            <TextInput required={true} onValueChange={(value)=>{setNewBot({
                                ...newBot,
                                bot_name:value
                            })}} className={styles.inputField} placeholder='Bot name' />
                        </div>
                        <div style={{width:"35%"}} className={styles.field}>
                            <Title className={styles.fieldName}>Type</Title>
                            <TextInput required={true} onValueChange={(value)=>{setNewBot({
                                ...newBot,
                                type:value
                            })}} className={styles.inputField} placeholder='Standard' />
                        </div>
                    </div>
                    <div  className={styles.inputSet}>
                        <div style={{width:"30%"}}  className={styles.field}>
                            <Title className={styles.fieldName}>Instruments</Title>
                            <TextInput required={true} onValueChange={(value)=>{setNewBot({
                                ...newBot,
                                instrument:value
                            })}} className={styles.inputField} placeholder='Instruments name' />
                        </div>
                        <div style={{width:"35%"}}  className={styles.field}>
                            <Title className={styles.fieldName}>Registered Script</Title>
                            <TextInput /* onValueChange={(value)=>{setNewBot({
                                ...newBot,
                                type:value
                            })}} */ className={styles.inputField} placeholder='Select from the dropdown' />
                        </div>
                    </div>
                </div>
                <div>
                    <Button type='submit' onClick={()=>createBot(newBot)} color='orange' variant='secondary'>Save Bot</Button>
                </div>
            </form>
        </div>
    )
}

export default AddBot