import { Button, Card, TextInput, Title } from '@tremor/react';
import styles from './login.module.scss';
import { useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../app/shared';
import { useAppDispatch } from '../../app/hooks';
import { setAuth, setLogin } from '../../app/redux_slices/auth_slice';
import { useNavigate } from 'react-router-dom';
import { setHome } from '../../app/redux_slices/route_slice';
import { handleError } from '../../global/ErrorHandling/errohandler';

interface Login{
  email:string | undefined,
  password:string | undefined
}
function Login() {
  const [credentials, setCreadentials]=useState<Login>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  async function LoginAPI(loginData:Login){
    try {
      const response = await axios.post(`${baseUrl}/api/admin/login`,loginData);
      if(response.status===200 || response.status===201){
        dispatch(setAuth(response.data.acess_token))
        dispatch(setLogin());
        navigate('/home');
        dispatch(setHome())
      }
    } catch (error) {
      handleError(error);
    }
  }

  return (
    <div className={styles.login}>
      <Card className={styles.card}>
        <Title className={styles.head}>AlgoT</Title>
        <p className={styles.desc}>Algotrading made easy</p>
        <Card className={styles.credentials}>
          <TextInput onValueChange={(value)=>setCreadentials({password:credentials?.password, email:value})} value={credentials?.email || ''} type='email' className={styles.input} placeholder='Username' />
          <TextInput onValueChange={(value)=>setCreadentials({email:credentials?.email, password:value})} value={credentials?.password || ''} type='password' className={styles.input} placeholder='Password' />
          <p>By proceeding, you agree to the Terms & Conditions and Privacy Policy</p>
          <Button onClick={() => credentials ? LoginAPI(credentials): alert('Fields are empty')} className={styles.button}>Admin Login</Button>
        </Card>
      </Card>
    </div>
  )
}

export default Login