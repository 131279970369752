import { DateRangePicker, TextInput, Button, Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell, Card, Select, SelectItem, SearchSelect, SearchSelectItem, Title, NumberInput } from '@tremor/react';
import styles from './signal.module.scss';
import { IoSearchSharp } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import tableData from './signal.json';
import { useEffect, useState } from 'react';
import { addSignal, editSignal, initial_state, View } from '../../interface/View';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { AddTrade, BotDetails, Edit, initial_signal_state, SignalFilter, SignalResponse, Signals, TradeData } from '../../interface/Signal';
import axios from 'axios';
import { baseUrl } from '../../app/shared';
import { useAuthHeaders } from '../../app/header';
import { ClipLoader, MoonLoader, ScaleLoader } from 'react-spinners';
import { useInView } from 'react-intersection-observer';
import SignalDetails from './signalDetails';
import { handleError } from '../../global/ErrorHandling/errohandler';
import dayjs from 'dayjs';
import { useEnterKey } from '../../global/Componnets/utils/enterAction';


function Signal() {
    const [view, setView]=useState<View>(initial_state);
    const [signalData, setSignalData]=useState<SignalResponse>();
    const {data_header}=useAuthHeaders();
    const [waitId, setWaitId]=useState<string[]>([]);
    const [botDetails, setBotDetails]=useState<BotDetails[]>()
    const [orderDetails, setOrderDetails] = useState<AddTrade>()
    const [bot, setBot]=useState<string>();
    const [value, setValue] = useState<string>();
    const [tradeData, setTradeData]= useState<TradeData[]>();
    const [paginatedInstrument, setPaginatedInstrument]= useState<TradeData[]>();
    const [selectedTrade, setSelectedTrade]=useState<TradeData>();
    const [tradeName, setTradeName] = useState<string>();
    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce:false
    });
    const [loadTable, setLoadTable]=useState<boolean>(false)
    const [currentView, setCurrentView]=useState(1);
    const [instrument, setInstrument]= useState<TradeData[]>();
    const [viewSignal, setViewSignal]=useState(false);
    const [signalSignal, setSingleSignal]=useState<Signals>();
    const [filter, setFilter]=useState<SignalFilter>(initial_signal_state);
    const [edit, setEdit]=useState<Edit>();

    async function getSignalData({from_date,to_date,param, page}:SignalFilter) {
        var url = `${baseUrl}/api/admin/get-signal-details?page=${page}&size=10`;
        if (param) url += `&param=${encodeURIComponent(param)}`;
        if (from_date) url += `&from_date=${encodeURIComponent(from_date)}`;
        if (to_date) url += `&to_date=${encodeURIComponent(to_date)}`;

        try {
            const response = await axios.get(url,data_header);
            if(response.status===200|| response.status===201){
                setSignalData(response.data);
            }
        } catch (error) {
            handleError(error);
        }
    }

    async function callForIndex(value : string){
        setPaginatedInstrument([]);
        const security={
            isin_name:value,
            signal:bot
        }
        try{
            const response = await axios.post(`${baseUrl}/api/admin/search-security`,security,data_header);
            if(response.status==200){
                setTradeData(response.data.data);
                setInstrument(response.data.data);
            }
        }catch(error: any){
            handleError(error);
        }
    }

    useEffect(()=>{
        setPaginatedInstrument(undefined);
        value && value.length<=3 && setSelectedTrade(undefined);
        value && value.length>=3 && callForIndex(value);
    },[value])

    useEffect(()=>{
        setCurrentView(currentView+1)
        console.log(paginatedInstrument && paginatedInstrument.length-1)
    },[inView])

    function waitTwo(signal_id:string){
        setWaitId((prev)=>(
            prev.filter(item=>(
                item!==signal_id
            ))
            ))
            console.log('Trade Squared Off');
            getSignalData(filter);
        }

    async function handleSquare(signal_id:string) {
        try {
            const response = await axios.post(`${baseUrl}/api/admin/signal/square-off`, {tradesignal_numeric_id:signal_id}, data_header);
            if(response.status===200 || response.status===201){
                setWaitId([...waitId, signal_id])
                setTimeout(()=>{ waitTwo(signal_id)}, 10000);
            }
        } catch (error) {
            handleError(error);
        }
    }

    async function getBotData(){
        console.log("Called");
        try{
            const response = await axios.get(`${baseUrl}/api/admin/trading-bots`,data_header);
            if(response.status===200 || response.status===201){
                setBotDetails(response.data.data);
                console.log(response.data)
            }
        }catch(error:any){
            handleError(error);
        }
}

    useEffect(()=>{
        getSignalData(filter);
    },[])

    useEffect(()=>{
        getBotData();
    },[view.popUps.addSignal])

    function handleData(name:string, value: any){
        setOrderDetails((item: any)=>{
            return{
                ...item,
                [name]:value
            }
        })
    }

    const paginateData = (data: TradeData[]) => {
        const startIndex = (currentView - 1) * 2;
        const endIndex = startIndex + 40;
        setPaginatedInstrument((prev)=>(
            [
                //...prev,
                ...data.slice(0, endIndex)
            ]
        ))
    };

    function waitOne(){
        getSignalData(filter);
        setLoadTable(false)
    }

    async function EditSignal(data:Edit) {
        try {
            const response = await axios.post(`${baseUrl}/api/admin/edit-trade-signal`, data, data_header);
            if(response.status===200 || response.status===201){
                setView(initial_state);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const submitData = async ()=>{
        console.log(orderDetails);
        try {
            const response = await axios.post(`${baseUrl}/api/admin/add-trade-signal`, orderDetails, data_header);
            if (response.status === 200) {
                setLoadTable(true)
                setTimeout(waitOne, 3000);
                setValue("");
                setBot("");
                setOrderDetails(undefined);
            }
        } catch (error: any) {
            handleError(error);
        }
        finally{
            setView(initial_state)
            setOrderDetails(undefined);
            setSelectedTrade(undefined)
            setInstrument(undefined);
            setBot(undefined);
        }
    }

    useEffect(()=>{
        instrument && paginateData(instrument);
    },[instrument, currentView])

    useEffect(()=>{
        filter.param==="" && getSignalData(filter);
    },[filter.param])

    useEffect(()=>{
        getSignalData(filter);
    },[filter.from_date, filter.to_date])

    useEnterKey(() => getSignalData(filter));

    return (
        <div className={styles.signal}>
            <div className={styles.filter}>
                <TextInput onValueChange={(value)=>setFilter(prev=>(
                    {
                        ...prev,
                        param:value
                    }
                ))} placeholder='Instrument, bot, signal id' className={styles.input} />
                <DateRangePicker onValueChange={(value)=>{
                    const formattedFrom = value.from
                        ? dayjs(value.from).format("YYYY-MM-DD")
                        : undefined;
                    const formattedTo = value.to
                        ? dayjs(value.to).format("YYYY-MM-DD")
                        : undefined;
            
                    setFilter((prev) => ({
                        ...prev,
                        from_date: formattedFrom,
                        to_date: formattedTo,
                    }));
                }} />
                <Button onClick={()=>getSignalData(filter)} variant='secondary' color={'orange'} icon={IoSearchSharp}>Search</Button>
                <Button onClick={() => setView(addSignal)} variant='secondary' color={'orange'} icon={FiPlus}>Add Signal</Button>
            </div>
            <div className={styles.tableContainer}>
                <div className={styles.pagination}><p>Showing page {signalData?.current_page || 1} of {signalData?.total_pages || 1}</p> 
                    <FaAngleLeft onClick={() =>{ signalData?.current_page !== 1 && getSignalData({from_date:undefined, param:undefined, to_date:undefined, page:(signalData?.current_page || 1)-1}); setFilter({from_date:undefined, param:undefined, to_date:undefined, page:(signalData?.current_page || 1)-1})}} />
                    <FaAngleRight onClick={() =>{ signalData?.current_page !== signalData?.total_pages && getSignalData({from_date:undefined, param:undefined, to_date:undefined, page:(signalData?.current_page || 1)+1}); setFilter({from_date:undefined, param:undefined, to_date:undefined, page:(signalData?.current_page || 1)+1})}} />
                </div>
                <div className={styles.table}>
                    <Table className={styles.mainTable}>
                        <TableHead className={styles.tableHead}>
                            <TableRow  className={styles.tableRow}>
                                <TableHeaderCell style={{color:"#D87E60"}} className={styles.headItems}>Signal ID</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60"}}>Date Time</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60"}}>BOT</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60"}}>Instrument</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60"}}>Buy/Sell Price</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60"}}>P/L</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60", textAlign:"right"}}>Users</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60" , textAlign:"right"}}>Status</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60", textAlign:"right"}}>Action</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                    loadTable &&
                    <TableRow >
                        {
                        [1,2,3,4,5,6,7,8,9].map(item=>(
                            <TableCell key={item} ><div><ClipLoader color='#8c8c8c'size={10} /></div></TableCell>
                        ))
                        }
                    </TableRow>
                }
                            {
                                signalData?.data.map((value, index)=>(
                                    <TableRow  onClick={()=>setSingleSignal(value)} key={index}>
                                        <TableCell onClick={()=>setViewSignal(true)}>{value.tradesignal_numeric_id}</TableCell>
                                        <TableCell onClick={()=>setViewSignal(true)}>{value.start_action_datetime}</TableCell>
                                        <TableCell onClick={()=>setViewSignal(true)}>{value.bot_name}</TableCell>
                                        <TableCell className='text-start' onClick={()=>setViewSignal(true)}>{value.trade_name}</TableCell>
                                        <TableCell onClick={()=>setViewSignal(true)}>{value.start_action_price} / {value.start_action_price}</TableCell>
                                        <TableCell onClick={()=>setViewSignal(true)}>{value.target_trigger_price}</TableCell>
                                        <TableCell onClick={()=>setViewSignal(true)} style={{ textAlign:"center"}}>{value.no_of_users}</TableCell>
                                        <TableCell style={{ textAlign:"right"}}>{((waitId.find(ids=>
                                                ids===value.tradesignal_numeric_id
                                                ))===value.tradesignal_numeric_id) ? 
                                            <div><ScaleLoader color='#0591f5' height={15}/></div> : value.status == false ? (value.end_action==null ? "Not Found" : value.end_action) : <Button style={{padding:'2px 8px'}} variant='secondary' color='orange' onClick={() => {handleSquare(value.tradesignal_numeric_id)} }>{value.end_action}</Button>}</TableCell>
                                        <TableCell style={{ textAlign:"right"}}><Button color='green' onClick={()=>{setView(editSignal); setEdit({signal_numeric_id:value.tradesignal_numeric_id, updated_sl_value:undefined, updated_target_value:undefined })}} style={{padding:'2px 8px'}} variant='secondary'>Edit</Button></TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </div>
            </div>
            {
                view.bg &&
                <div className={styles.bg}>
                    {
                        view.popUps.addSignal &&
                        <Card className={styles.addSignal}>
                            <div className={styles.topBar}>Add Signal</div>
                            <div className={styles.interaction}>
                                <div style={{paddingTop:"3.5rem"}} className={styles.one}>
                                    <Select placeholder='Select Bot....' onValueChange={(value: string)=>{
                                        handleData('bot_name', value)
                                        const selectedBot = botDetails?.find((item) => item.bot_name === value);
                                        if (selectedBot) {
                                            setBot(selectedBot.instrument);
                                        }
                                        }}>
                                        {
                                            botDetails?.map((item:BotDetails,index:number)=>(
                                            <SelectItem onClick={()=>setBot(item.instrument)} key={item.instrument} value={item.bot_name}>{item.bot_name}</SelectItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        orderDetails?.bot_name &&
                                        <div className={styles.info}>
                                            <Title>INFO:</Title>
                                            <p className={styles.desc}>Have Five Instrument under bot {orderDetails?.bot_name}</p>
                                        </div>
                                    }
                                    <div className={styles.data}>
                                        <p>{bot}</p>
                                    </div>
                                </div>
                                <div style={{paddingTop:"3.5rem"}} className={styles.one}>
                                    <TextInput value={value}  disabled={bot===""} placeholder='Select Instrument' onValueChange={(newValue)=>{setValue(newValue)}} />
                                    { (value && !paginatedInstrument) &&
                                    <div className={styles.loading}><MoonLoader size={20}  /></div>}
                                        { paginatedInstrument &&
                                            <div className={styles.selectBox}>
                                            {
                                            paginatedInstrument.map((item: TradeData, index:number)=>(
                                                <option
                                                    className={styles.card}
                                                    key={index}
                                                    ref={paginatedInstrument.length - 1 === index ? ref : null}
                                                    onClick={() => {
                                                        setValue("")
                                                        setSelectedTrade(item);
                                                        setPaginatedInstrument(undefined);
                                                        setInstrument(undefined);
                                                        handleData('instrument_token', item.instrument_key);
                                                        handleData('transaction_type', 'BUY');
                                                        handleData('price_type', 'points');
                                                        handleData('is_amo', false);
                                                        handleData('symbol_token', item.exchange_token.toString());
                                                        handleData('trading_symbol', item.trading_symbol);
                                                        setTradeName(`${item.name == null ? "No Name" : item.name} | ${item.instrument_key}, `);
                                                    }}
                                                    value={item.trading_symbol}
                                                    >
                                                    {item.short_name == null ? item.trading_symbol : item.name}
                                                </option>
                                            ))}
                                    </div>}
                                {
                                    selectedTrade &&
                                    <div className={styles.info}>
                                        <Title>INFO:</Title>
                                        <p className={styles.desc}>{selectedTrade?.isin}</p>
                                    </div>
                                }
                                {
                                    selectedTrade &&
                                    <div className={styles.data}>
                                        <p>Lot Size :  {selectedTrade?.lot_size}</p>
                                        <p>Code: { selectedTrade?.short_name == null ? selectedTrade?.trading_symbol : selectedTrade?.name}</p>
                                        {/* <p>52 High: 340</p>
                                        <p>52 low:: 340</p> */}
                                    </div>
                                }
                                </div>
                                <div className={styles.two}>
                                    <div className={styles.interaction}>
                                        <div className={styles.top}>Delivery</div>
                                        <div className={styles.bottom}>
                                            <Button disabled={/* selectedTrade===undefined || orderDetails?.product==="D" */ true} onClick={()=>{ handleData('product', 'D')}} variant='secondary' color='orange' /* style={{background:"#32354C", color:"#D67253"}} */>Carry</Button>
                                            <Button disabled={selectedTrade===undefined } onClick={()=>{ handleData('product', 'I')}} variant='secondary' style={orderDetails?.product==="I" ? {background:"#32354C", color:"#D67253"} : {}} color='orange'>IntraDay</Button>
                                        </div>
                                    </div>
                                    <div className={styles.interaction}>
                                    <div className={styles.top}>Order Type</div>
                                        <div className={styles.bottom}>
                                            <Button disabled={selectedTrade===undefined } onClick={()=>{handleData('order_type', 'MARKET');handleData('price',  0)}} variant='secondary' color='orange' style={orderDetails?.order_type==="MARKET" ? {background:"#32354C", color:"#D67253"} : {}}>Market</Button>
                                            <Button  disabled={/* selectedTrade===undefined || orderDetails?.order_type==="LIMIT" */ true} onClick={()=>{handleData('order_type', 'LIMIT');}} variant='secondary' color='orange'>Limit</Button>
                                        </div>
                                    </div>
                                    <div className={styles.interactionBottom}>
                                        <div className={styles.inputs}>
                                            <Title className={styles.name}>Buy Price</Title>
                                            <NumberInput value={orderDetails?.price} enableStepper={false} onValueChange={(value: any)=>{handleData('price', Number.isNaN(value)? 0: value);}} disabled={orderDetails?.order_type=='MARKET' || selectedTrade===undefined} placeholder='Price...' className={styles.dataInput} />
                                        </div>
                                        <div className={styles.inputs}>
                                            <Title className={styles.name}>S/L Price</Title>
                                            <NumberInput value={orderDetails?.sl_value} disabled={selectedTrade===undefined} enableStepper={false} onValueChange={(value: any)=>{ handleData('sl_value', value)}} placeholder='S/L Price...' className={styles.dataInput} />
                                        </div>
                                        <div className={styles.inputs}>
                                            <Title className={styles.name}>Target Price</Title>
                                            <NumberInput value={orderDetails?.target_value} disabled={selectedTrade===undefined} enableStepper={false} onValueChange={(value: any)=>{ handleData('target_value', value);}} placeholder='Target Price...' className={styles.dataInput} />
                                        </div>
                                        <div className={styles.buttonArea}>
                                            <Button onClick={()=>{setView(initial_state); setOrderDetails(undefined); setSelectedTrade(undefined); setBot(undefined)}} color='gray' style={{borderWidth:"1.5px"}} variant='secondary'>Close</Button>
                                            <Button onClick={()=>{submitData()}} disabled={selectedTrade===undefined} style={{background:"#D87E60", color:"#32354C", borderWidth:"1.5px", borderColor:"#32354C"}}>Buy</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    }
                    {
                        view.popUps.editSignal &&
                        <Card className={styles.editSignal}>
                            <div className={styles.topBar}>Edit Signal</div>
                            <div className={styles.interaction}>
                                <div className={styles.one}>
                                    <div>
                                        <Title>BOT</Title>
                                    </div>
                                    <div>
                                        <Title>INSTRUMENT</Title>
                                    </div>
                                    <div>
                                        <Title>Current Signal</Title>
                                    </div>
                                </div>
                                <div className={styles.two}>
                                <div className={styles.interaction}>
                                        <div className={styles.top}>Delivery</div>
                                        <div className={styles.bottom}>
                                            <Button disabled={selectedTrade===undefined || orderDetails?.product==="D"} onClick={()=>{ handleData('product', 'D')}} style={{background:"#32354C", color:"#D67253"}}>Carry</Button>
                                            <Button disabled={selectedTrade===undefined || orderDetails?.product==="I"} onClick={()=>{ handleData('product', 'I')}} variant='secondary' color='orange'>IntraDay</Button>
                                        </div>
                                    </div>
                                    <div className={styles.interaction}>
                                    <div className={styles.top}>Order Type</div>
                                        <div className={styles.bottom}>
                                            <Button disabled={selectedTrade===undefined || orderDetails?.order_type==="MARKET"} onClick={()=>{handleData('order_type', 'MARKET');handleData('price',  0)}} style={{background:"#32354C", color:"#D67253"}}>Market</Button>
                                            <Button disabled={selectedTrade===undefined || orderDetails?.order_type==="LIMIT"} onClick={()=>{handleData('order_type', 'LIMIT');}} variant='secondary' color='orange'>Limit</Button>
                                        </div>
                                    </div>
                                    <div className={styles.interactionBottom}>
                                        <div className={styles.inputs}>
                                            <Title className={styles.name}>Buy Price</Title>
                                            <NumberInput value={orderDetails?.price} enableStepper={false} onValueChange={(value: any)=>{handleData('price', Number.isNaN(value)? 0: value);}} disabled={orderDetails?.order_type=='MARKET' || selectedTrade===undefined} placeholder='Price...' className={styles.dataInput} />
                                        </div>
                                        <div className={styles.inputs}>
                                            <Title className={styles.name}>S/L Price</Title>
                                            <NumberInput value={edit?.updated_sl_value}  enableStepper={false} onValueChange={(value: any)=>{ setEdit({signal_numeric_id:edit?.signal_numeric_id, updated_sl_value:value, updated_target_value:edit?.updated_target_value})}} placeholder='S/L Price...' className={styles.dataInput} />
                                        </div>
                                        <div className={styles.inputs}>
                                            <Title className={styles.name}>Target Price</Title>
                                            <NumberInput value={edit?.updated_target_value}  enableStepper={false} onValueChange={(value: any)=>{ setEdit({signal_numeric_id:edit?.signal_numeric_id, updated_sl_value:edit?.updated_sl_value, updated_target_value:value})}} placeholder='Target Price...' className={styles.dataInput} />
                                        </div>
                                        <div className={styles.buttonArea}>
                                            <Button onClick={()=>setView(initial_state)} color='gray' style={{borderWidth:"1.5px"}} variant='secondary'>Close</Button>
                                            <Button onClick={()=>{submitData()}} disabled={selectedTrade===undefined} style={{background:"#D87E60", color:"#32354C", borderWidth:"1.5px", borderColor:"#32354C"}}>Update</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    }
                </div>
            }
            {
                viewSignal &&
                <SignalDetails hide={()=>{setViewSignal(false)}} signalDetails={signalSignal}  />
            }
        </div>
    )
}

export default Signal